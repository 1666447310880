<template>
  <n-space class="p-top" size="large" justify="center">
    <n-spin :show="loading" style="padding-top: 20px">
      <n-card :title="t('signIn.title')">
        <template #cover>
          <img
            alt=""
            :src="`${publicPath}assets/images/logo/logo.png`"
            style="width: 100px !important; height: auto !important"
          />
        </template>
        <n-form
          :label-width="100"
          :model="formValue"
          :rules="rules"
          ref="formRef"
          @submit.prevent
        >
          <n-form-item :label="t('signIn.form.email.label')" path="email">
            <n-input
              v-model:value="formValue.email"
              :placeholder="t('signIn.form.email.placeholder')"
              :disabled="isConfirmUserInProcess"
            />
          </n-form-item>
          <n-form-item
            :label="t('signIn.form.firstName.label')"
            path="firstName"
            v-if="isCreateUserInProcess"
          >
            <n-input
              v-model:value="formValue.firstName"
              :placeholder="t('signIn.form.firstName.placeholder')"
            />
          </n-form-item>
          <n-form-item
            :label="t('signIn.form.lastName.label')"
            path="lastName"
            v-if="isCreateUserInProcess"
          >
            <n-input
              v-model:value="formValue.lastName"
              :placeholder="t('signIn.form.lastName.placeholder')"
            />
          </n-form-item>
          <n-form-item
            :label="
              isCreateUserInProcess
                ? t('signIn.form.newPassword.label')
                : t('signIn.form.password.label')
            "
            path="password"
            v-if="
              (!isResetPasswordInProgress && !isConfirmUserInProcess) ||
              isConfirmResetPasswordInProgress
            "
            @keyup.enter="handleValidateClick"
          >
            <n-input
              :placeholder="
                isCreateUserInProcess
                  ? t('signIn.form.newPassword.placeholder')
                  : t('signIn.form.password.placeholder')
              "
              type="password"
              show-password-on="mousedown"
              v-model:value="formValue.password"
            />
          </n-form-item>

          <n-form-item
            first
            v-if="isCreateUserInProcess || isConfirmResetPasswordInProgress"
            path="reenteredPassword"
            :label="t('signIn.form.repeatPassword.label')"
            @keyup.enter="handleValidateClick"
          >
            <n-input
              :disabled="!formValue.password"
              :placeholder="t('signIn.form.repeatPassword.placeholder')"
              v-model:value="formValue.reenteredPassword"
              type="password"
            />
          </n-form-item>

          <n-form-item
            :label="t('signIn.form.phone.label')"
            path="phone"
            v-if="isCreateUserInProcess"
          >
            <n-input
              v-model:value="formValue.phone"
              :placeholder="t('signIn.form.phone.placeholder')"
            />
          </n-form-item>
          <n-form-item
            :label="t('signIn.form.code.label')"
            path="code"
            v-if="isConfirmUserInProcess || isConfirmResetPasswordInProgress"
          >
            <n-input
              v-model:value="formValue.code"
              :placeholder="t('signIn.form.code.placeholder')"
            />
          </n-form-item>
          <n-space vertical justify="start">
            <span
              v-if="
                !isCreateUserInProcess &&
                !isResetPasswordInProgress &&
                !isConfirmUserInProcess &&
                !isConfirmResetPasswordInProgress
              "
            >
              <span>{{ t("signIn.links.forgotPassword.label") }}&nbsp;</span>
              <n-button
                class="p-bottom"
                text
                tag="a"
                type="primary"
                @click="() => (isResetPasswordInProgress = true)"
              >
                {{ t("signIn.links.forgotPassword.text") }}
              </n-button>
            </span>
            <span
              v-if="
                !isCreateUserInProcess &&
                !isResetPasswordInProgress &&
                !isConfirmUserInProcess &&
                !isConfirmResetPasswordInProgress
              "
            >
              <span>{{ t("signIn.links.createAccount.label") }}&nbsp;</span>
              <n-button
                class="p-bottom"
                text
                tag="a"
                type="primary"
                @click="() => (isCreateUserInProcess = true)"
              >
                {{ t("signIn.links.createAccount.text") }}
              </n-button>
            </span>
            <span v-if="isCreateUserInProcess">
              {{ t("signIn.links.haveAccount.label") }}&nbsp;
              <n-button
                class="p-bottom"
                text
                tag="a"
                type="primary"
                @click="
                  () => {
                    isCreateUserInProcess = false;
                    isResetPasswordInProgress = false;
                  }
                "
              >
                {{ t("signIn.links.haveAccount.text") }}
              </n-button>
            </span>
            <span v-if="isConfirmUserInProcess">
              {{ t("signIn.links.lostCode.label") }}&nbsp;
              <n-button
                class="p-bottom"
                text
                tag="a"
                type="primary"
                @click="resendCode()"
              >
                {{ t("signIn.links.lostCode.text") }}
              </n-button>
            </span>
            <span
              v-if="
                isResetPasswordInProgress ||
                isConfirmUserInProcess ||
                isConfirmResetPasswordInProgress
              "
            >
              <n-button
                class="p-bottom"
                text
                tag="a"
                type="primary"
                @click="
                  () => {
                    isCreateUserInProcess = false;
                    isResetPasswordInProgress = false;
                    isConfirmUserInProcess = false;
                  }
                "
              >
                {{ t("commons.actions.back") }}
              </n-button>
            </span>
          </n-space>
          <n-form-item class="align-end">
            <n-button @click="handleValidateClick">
              <template #icon>
                <n-icon>
                  <LoginIcon
                    v-if="
                      !isResetPasswordInProgress &&
                      !isCreateUserInProcess &&
                      !isConfirmUserInProcess &&
                      !isConfirmResetPasswordInProgress
                    "
                  />
                  <SendCodeIcon
                    v-if="isResetPasswordInProgress || isConfirmResetPasswordInProgress"
                  />
                  <CreateUserIcon v-if="isCreateUserInProcess" />
                  <ConfirmationCodeIcon v-if="isConfirmUserInProcess" />
                </n-icon>
              </template>
              {{
                isConfirmResetPasswordInProgress
                  ? t("commons.actions.submit")
                  : isResetPasswordInProgress
                  ? t("commons.actions.send", { reference: t("commons.labels.code", 1) })
                  : isCreateUserInProcess
                  ? t("commons.actions.create", {
                      reference: t("commons.labels.account", 1),
                    })
                  : isConfirmUserInProcess
                  ? t("commons.actions.confirm")
                  : t("commons.actions.signIn")
              }}
            </n-button>
          </n-form-item>
        </n-form>
      </n-card>
    </n-spin>
  </n-space>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted, computed } from "vue";
import {
  useMessage,
  NSpace,
  NSpin,
  NCard,
  NForm,
  NFormItem,
  NInput,
  NButton,
  NIcon,
} from "naive-ui";
import { useStore } from "vuex";
import {
  LogInOutline as LoginIcon,
  CheckboxOutline as ConfirmationCodeIcon,
} from "@vicons/ionicons5";
import { Password as SendCodeIcon, UserFollow as CreateUserIcon } from "@vicons/carbon";
import { Auth } from "aws-amplify";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { COUNTRY_CODE } from "@/shared/constants.js";

export default defineComponent({
  components: {
    LoginIcon,
    SendCodeIcon,
    CreateUserIcon,
    ConfirmationCodeIcon,
    NSpace,
    NSpin,
    NCard,
    NForm,
    NFormItem,
    NInput,
    NButton,
    NIcon,
  },
  props: {
    redirect: String,
  },
  setup(props) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const router = useRouter();
    const formRef = ref(null);
    const message = useMessage();
    const store = useStore();
    const formValue = ref({
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      reenteredPassword: null,
      phone: "",
      code: "",
    });

    const isResetPasswordInProgress = ref(false);
    const isConfirmResetPasswordInProgress = ref(false);
    const isCreateUserInProcess = ref(false);
    const isConfirmUserInProcess = ref(false);
    const loading = ref(false);
    const is_acceptedDoR = computed(() => store.getters["user/is_acceptedDoR"]);

    onMounted(() => {
      store.dispatch("app/hideHeader");
    });

    onUnmounted(() => {
      store.dispatch("app/showHeader");
    });

    const signIn = async (createUserOnDB = false) => {
      try {
        loading.value = true;
        const authUser = await Auth.signIn(
          formValue.value.email,
          formValue.value.password
        );
        store.dispatch("auth/setContextUser");
        if (createUserOnDB) {
          const newUser = {
            id: authUser.attributes.sub,
            email: authUser.attributes.email,
            name: authUser.attributes.name,
            familyName: authUser.attributes.family_name,
            phoneNumber: authUser.attributes.phone_number,
          };
          await store.dispatch("user/createUser", newUser);
        } else {
          await store.dispatch("user/getUserById", authUser.attributes.sub);
        }
        if (is_acceptedDoR.value) {
          console.debug("dor aceptado, suscribiendo notificaciones");
          store
            .dispatch("notifications/FillNotifications", store.getters["auth/user_id"])
            .then(() =>
              store.dispatch(
                "notifications/SubscribeNotificationWeb",
                store.getters["auth/user_id"]
              )
            );
          if (props.redirect !== undefined && props.redirect !== null) {
            router.push(props.redirect);
          } else {
            router.push("/");
          }
        } else {
          console.debug("dor no aceptado, mostrando dor nuevamente");
          router.push({ name: "discharge-responsibility" });
        }
      } catch (error) {
        message.error(t("signIn.messages.errorAuthenticating"));
        if (error.code === "UserNotConfirmedException") {
          isConfirmUserInProcess.value = true;
        }
      } finally {
        loading.value = false;
      }
    };

    const resendCode = async () => {
      try {
        loading.value = true;
        await Auth.resendSignUp(formValue.value.email);
        message.success(t("signIn.messages.codeSent"));
      } catch (err) {
        message.error(err.message);
      } finally {
        loading.value = false;
      }
    };

    const signUp = async () => {
      try {
        loading.value = true;
        await Auth.signUp({
          username: formValue.value.email,
          password: formValue.value.password,
          attributes: {
            email: formValue.value.email,
            phone_number: COUNTRY_CODE + formValue.value.phone,
            name: formValue.value.firstName,
            family_name: formValue.value.lastName,
          },
        });
        isCreateUserInProcess.value = false;
        isConfirmUserInProcess.value = true;
      } catch (error) {
        message.error(error.message);
      } finally {
        loading.value = false;
      }
    };

    const confirmSignUp = async () => {
      try {
        loading.value = true;
        await Auth.confirmSignUp(formValue.value.email, formValue.value.code);
        isConfirmUserInProcess.value = false;
        message.success(
          t("commons.actions.confirmed", { reference: t("commons.labels.email", 1) })
        );
        signIn(true);
      } catch (error) {
        message.error(error.message);
      } finally {
        loading.value = false;
      }
    };

    const forgotPassword = async () => {
      try {
        loading.value = true;
        await Auth.forgotPassword(formValue.value.email);
        isResetPasswordInProgress.value = false;
        isConfirmResetPasswordInProgress.value = true;
        message.success(t("signIn.messages.codeSentForNewPassword"));
      } catch (error) {
        message.error(error.message);
      } finally {
        loading.value = false;
      }
    };

    const forgotPasswordSubmit = async () => {
      try {
        loading.value = true;
        await Auth.forgotPasswordSubmit(
          formValue.value.email,
          formValue.value.code,
          formValue.value.password
        );
        isConfirmResetPasswordInProgress.value = false;
        formValue.value.email = null;
        formValue.value.password = null;
        message.success(
          t("commons.actions.changed", { reference: t("commons.labels.password", 1) })
        );
      } catch (error) {
        message.error(error.message);
      } finally {
        loading.value = false;
      }
    };

    function validatePasswordStartWith(rule, value) {
      return (
        formValue.value.password &&
        formValue.value.password.startsWith(value) &&
        formValue.value.password.length >= value.length
      );
    }
    function validatePasswordSame(rule, value) {
      return value === formValue.value.password;
    }
    return {
      t,
      formRef,
      formValue,
      rules: ref({
        email: {
          required: true,
          type: "email",
          message: t("commons.form.validation.required"),
          trigger: ["input"],
        },
        firstName: [
          {
            required: true,
            message: t("commons.form.validation.required"),
            trigger: ["input"],
          },
        ],
        lastName: {
          required: true,
          message: t("commons.form.validation.required"),
          trigger: ["input"],
        },
        password: {
          required: true,
          message: t("commons.form.validation.required"),
          trigger: ["input"],
        },
        reenteredPassword: [
          {
            required: true,
            message: t("commons.form.validation.required"),
            trigger: ["input", "blur"],
          },
          {
            validator: validatePasswordStartWith,
            message: t("signIn.form.validations.passwordNotSame"),
            trigger: "input",
          },
          {
            validator: validatePasswordSame,
            message: t("signIn.form.validations.passwordNotSame"),
            trigger: ["blur", "password-input"],
          },
        ],
        phone: {
          required: true,
          message: t("commons.form.validation.required"),
          trigger: ["input"],
          pattern: "(?:^(?:(?:1?){1})([2-9]{1}\\d{2})([2-9]{1}\\d{2})(\\d{4}))$",
        },
        code: {
          required: true,
          message: t("commons.form.validation.required"),
          trigger: ["input"],
        },
      }),
      handleValidateClick() {
        formRef.value.validate((errors) => {
          if (!errors) {
            if (isCreateUserInProcess.value) {
              signUp();
            } else if (isConfirmUserInProcess.value) {
              confirmSignUp();
            } else if (isResetPasswordInProgress.value) {
              forgotPassword();
            } else if (isConfirmResetPasswordInProgress.value) {
              forgotPasswordSubmit();
            } else {
              signIn();
            }
          }
        });
      },
      isResetPasswordInProgress,
      isCreateUserInProcess,
      isConfirmUserInProcess,
      isConfirmResetPasswordInProgress,
      loading,
      resendCode,
      publicPath: process.env.BASE_URL,
    };
  },
});
</script>

<style scoped>
.p-top {
  padding-top: 70px;
}

.n-card {
  max-width: 100%;
  width: 350px;
}

:deep(.n-card-cover) {
  display: flex;
  justify-content: center;
}

img {
  height: 50px;
  padding-top: 20px;
  width: 50px !important;
}

.align-end {
  display: flex;
  justify-content: flex-end;
}

.p-bottom {
  padding-bottom: 20px;
}
</style>
